<template>
  <div class="misc-wrapper flex-column">
    <div class="d-flex justify-content-center">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </div>
    <div class="misc-inner p-2 p-sm-3">
      <validation-observer ref="registerAgentForm">
        <b-form @submit.prevent="onSubmit">
          <b-card>
            <b-card-title>{{ t('routes.agents_register') }}</b-card-title>

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="ระดับตัวแทน"
                  label-for="type"
                >
                  <b-form-select
                    id="type"
                    v-model="formData.type"
                    :options="options"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-group
                    label="* อีเมลสำหรับเข้าสู่ระบบ"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="formData.email"
                      placeholder="ตัวอย่าง : umoregame888@mail.com"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-group
                    label="* รหัสผ่าน"
                    label-for="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="formData.password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="assword"
                        placeholder="············"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                >
                  <b-form-group
                    label-for="password-confirm"
                    label="* ยืนยันรหัสผ่าน"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password-confirm"
                        v-model="formData.confirmPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="เว็บไซต์ของเกม"
                  rules="required"
                >
                  <b-form-group
                    label="* เว็บไซต์ของเกม"
                    label-for="web"
                  >
                    <b-form-input
                      id="web"
                      v-model="formData.web"
                      placeholder="ตัวอย่าง : https://umoregame.com"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ชื่อตัวแทน"
                  rules="required"
                >
                  <b-form-group
                    label="* ชื่อตัวแทน"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="formData.name"
                      placeholder="ตัวอย่าง : Umore Game"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ชื่อผู้ติดต่อ"
                  rules="required"
                >
                  <b-form-group
                    label="* ชื่อผู้ติดต่อ"
                    label-for="contact"
                  >
                    <b-form-input
                      id="contact"
                      v-model="formData.contact"
                      placeholder="ตัวอย่าง : สมชาย สายโชค"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="อีเมลผู้ติดต่อ"
                  rules=""
                >
                  <b-form-group
                    label="อีเมลผู้ติดต่อ"
                    label-for="contactEmail"
                  >
                    <b-form-input
                      id="contactEmail"
                      v-model="formData.contactEmail"
                      placeholder="somchai@mail.com"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="อีเมลสำหรับเรียกเก็บเงิน"
                  rules=""
                >
                  <b-form-group
                    label="อีเมลสำหรับเรียกเก็บเงิน"
                    label-for="cashEmail"
                  >
                    <b-form-input
                      id="cashEmail"
                      v-model="formData.cashEmail"
                      placeholder="ตัวอย่าง : finance@umore.com"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col class="text-center">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'agents_list'}"
                variant="outline-primary"
                class="mr-1"
              >
                ย้อนกลับ
              </b-button>
              <b-button
                variant="primary"
                type="submit"
              >
                สร้างบัญชี
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,

      formData: {
        email: '',
        type: '1',
        password: '',
        confirmPassword: '',
        web: '',
        contact: '',
        contactEmail: '',
        cashEmail: '',
      },

      options: [{ text: 'Provider', value: '1' }],
      password2FieldType: 'password',
    }
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.$refs.registerAgentForm.validate().then(async (success) => {
        if (success) {
          this.$router.replace('/register?agent=1234')
        } else {
          this.$router.replace('/register?agent=1234')
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
