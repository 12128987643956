var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"misc-wrapper flex-column"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Vuexy ")])],1),_c('div',{staticClass:"misc-inner p-2 p-sm-3"},[_c('validation-observer',{ref:"registerAgentForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',[_c('b-card-title',[_vm._v(_vm._s(_vm.t('routes.agents_register')))]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ระดับตัวแทน","label-for":"type"}},[_c('b-form-select',{attrs:{"id":"type","options":_vm.options},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* อีเมลสำหรับเข้าสู่ระบบ","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"ตัวอย่าง : umoregame888@mail.com","state":errors[0] ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* รหัสผ่าน","label-for":"password"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"assword","placeholder":"············"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"password-confirm","label":"* ยืนยันรหัสผ่าน"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"password-confirm","placeholder":"············"},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1)],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"เว็บไซต์ของเกม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* เว็บไซต์ของเกม","label-for":"web"}},[_c('b-form-input',{attrs:{"id":"web","placeholder":"ตัวอย่าง : https://umoregame.com","state":errors[0] ? false : null},model:{value:(_vm.formData.web),callback:function ($$v) {_vm.$set(_vm.formData, "web", $$v)},expression:"formData.web"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"ชื่อตัวแทน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* ชื่อตัวแทน","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"ตัวอย่าง : Umore Game","state":errors[0] ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"ชื่อผู้ติดต่อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"* ชื่อผู้ติดต่อ","label-for":"contact"}},[_c('b-form-input',{attrs:{"id":"contact","placeholder":"ตัวอย่าง : สมชาย สายโชค","state":errors[0] ? false : null},model:{value:(_vm.formData.contact),callback:function ($$v) {_vm.$set(_vm.formData, "contact", $$v)},expression:"formData.contact"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"อีเมลผู้ติดต่อ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"อีเมลผู้ติดต่อ","label-for":"contactEmail"}},[_c('b-form-input',{attrs:{"id":"contactEmail","placeholder":"somchai@mail.com","state":errors[0] ? false : null},model:{value:(_vm.formData.contactEmail),callback:function ($$v) {_vm.$set(_vm.formData, "contactEmail", $$v)},expression:"formData.contactEmail"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"อีเมลสำหรับเรียกเก็บเงิน","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"อีเมลสำหรับเรียกเก็บเงิน","label-for":"cashEmail"}},[_c('b-form-input',{attrs:{"id":"cashEmail","placeholder":"ตัวอย่าง : finance@umore.com","state":errors[0] ? false : null},model:{value:(_vm.formData.cashEmail),callback:function ($$v) {_vm.$set(_vm.formData, "cashEmail", $$v)},expression:"formData.cashEmail"}})],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"to":{ name: 'agents_list'},"variant":"outline-primary"}},[_vm._v(" ย้อนกลับ ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" สร้างบัญชี ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }